import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Nav from 'react-bootstrap/Nav' ;
import Logo from '../../static/vms_dtp_logo.svg';
import Header from './Header';
import MyVerticallyCenteredModal  from './Modal';
import FooterPage from './Footer';
import '../../static/index.css';
import img_1 from '../../static/office-img-1.jpg';
import img_2 from '../../static/office-img-2.jpg';
import LoaderSVG from '../../static/loader.svg';
import {jsx, css} from '@emotion/react'




export default () => {
const [modalShow, setModalShow] = React.useState(false);

const breakpoints = [576, 768, 992, 1200]

const mq = breakpoints.map(
  bp => `@media (max-width: ${bp}px)`
)
return (
    <div>
 <Header />
  <div
    key={`loader`}
              id="___loader"
            css={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FFF",
                justifyContent: "center",
                height:600,
                zIndex: 100,
                [mq[0]]: {
                  height:50,
                },
                [mq[1]]: {
                  height:100,
                },
                [mq[2]]: {
                  height:500,
                },
               }}     >
              <img 
                src={LoaderSVG} 
                alt="loading spinner" 
                width="25" 
                height="25"
              />
        </div>
    <Carousel id="my_id" style={{display:"none"}}>
  
  <Carousel.Item>
    <img
      className="image-responsive d-block w-100"
      src={img_1}
      alt="office-img-1"
    />
    <Carousel.Caption>
      <h3 className="carousel_cap">Greetings from VMS DTP</h3>
      <p className="carousel_para">Call us on +91 xxxxxxxxxx</p>
    </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
    <img
      className="image-responsive d-block w-100"
      src={img_2}
      alt="office-img-2"
    />

    <Carousel.Caption>
      <h3>Greetings from VMS DTP</h3>
      <p>Call us on +91 xxxxxxxxxx</p>
    </Carousel.Caption>
  </Carousel.Item>
  
</Carousel>
  <br/>
  
   <Container>
     <Row>
       <Col>
       <p> If you have got a dissertation, thesis`, or seminar report to submit -  look no further. </p>

<p> We accurately type and proof-read, and deliver error-free documents as soon as possible. </p>

<p>We type both English and Malayalam. </p>

<p>We can give a bound book or do typing only.   </p>

<ButtonToolbar  >
      <Button  variant="primary" style={{backgroundColor:"#008328", borderColor:"#008329"}} onClick={() => setModalShow(true)}>
        Please check our rates!
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </ButtonToolbar>

<p> Trusted by students. </p>

<p>Call now: +91 xxxxxxxxxx </p>
       </Col>
     </Row>
   </Container>

   <FooterPage />
</div> );
}

